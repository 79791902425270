import React, { useState } from 'react';
import styles from './HelpandSupport.module.css';

const HelpandSupport = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [serviceRequestNumber, setServiceRequestNumber] = useState('');

  const handleSend = () => {
    setIsUploading(true);

    // Simulate upload delay
    setTimeout(() => {
      setIsUploading(false);
      setUploadSuccess(true);
      setServiceRequestNumber(`SR-${Math.floor(Math.random() * 1000000)}`); // Generate a random service request number
    }, 3000); // 3 seconds delay for busy animation
  };

  return (
    <div className={styles.helpAndSupportPage}>
      <div className={styles.contactSection}>
        <h2>Contact Us</h2>
        <p><strong>Autoops LLP</strong></p>
        <p>Bangalore</p>
        <p>Email: <a href="mailto:contact@autoops.in">contact@autoops.in</a></p>
        <p>Phone: 1800XXXXXXXX</p>
      </div>

      <div className={styles.uploadSection}>
        <h2 className={styles.heading}>Send Telemetry Data</h2>
        <div className={styles.uploadBox}>
          <button onClick={handleSend} className={styles.searchButton}>Click to Send file</button>
          {isUploading && (
            <div className={styles.busyAnimation}>
              <div className={styles.loader}></div> 
              Sending file, please wait...
            </div>
          )}
          {uploadSuccess && (
            <div className={styles.uploadSuccessMessage}>
              <span className={styles.successIcon}>✔️</span>
              File sent successfully! Our team will contact you soon. Your service request number is &nbsp;<strong>{serviceRequestNumber}</strong>.
            </div>
          )}
        </div>
      </div>      
    </div>
  );
};

export default HelpandSupport;
