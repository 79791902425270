// Navbar.js
import React, { useState } from 'react';
import styles from './Navbar.module.css';
import logo from '../assets/logo.png'; // Import the logo from the root folder
import { FaUser, FaSignOutAlt, FaUserCircle } from 'react-icons/fa'; // Import icons from react-icons
import {useNavigate} from 'react-router-dom'

function Navbar() {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate()

  const handleSignout = () => {    
    navigate('/')
  };

  const handleUserProfile = () =>{
    navigate('/userprofile')
  }

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.logoContainer}>
        <img src={logo} alt="Logo" className={styles.logoImage} /> {/* Logo Image */}
        <div className={styles.brandNameContainer}>
          <span className={styles.brandNameTitle}>Autoops</span> {/* Main Title in Bigger Font */}
          <span className={styles.brandNameSubtitle}>Simplifying medical care</span> {/* Subtitle in Smaller Font */}
      </div>
      </div>
      
      <div className={styles.userMenu}>
        <div className={styles.userMenuButton} onClick={toggleMenu}> {/* User Menu Button */}
          <FaUserCircle className={styles.userIcon} /> {/* User Icon */}
          <span className={styles.userMenuText}>User</span> {/* User Menu Label */}
        </div>
        {isMenuOpen && (
          <div className={styles.dropdownMenu}>
            <div className={styles.menuItem}>
              <FaUser className={styles.menuIcon} /> {/* User Profile Icon */}
              <span onClick={handleUserProfile}>User Profile</span>
            </div>
            {/* <div className={styles.menuItem}>
              <FaCog className={styles.menuIcon} /> 
              <span>Settings</span>
            </div> */}
            <div className={styles.menuItem} onClick={handleSignout}>
              <FaSignOutAlt className={styles.menuIcon} /> {/* Sign Out Icon */}
              <span>Sign Out</span>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
