import React from 'react';
import UserProfileForm from '../components/UserProfileForm';
import Layout from '../components/Layout';

const UserProfilePage = () => {
  return (
    <Layout>
      <UserProfileForm />
    </Layout>
  );
};

export default UserProfilePage;
