import React from 'react';
import DataGridComponent from './Datagrid'; // Import the custom DataGridComponent

const AppointmentList = () => {
  // Appointment data
  const appointmentData = {
    columns: [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'patientName', headerName: 'Patient Name', width: 150 },
      { field: 'doctor', headerName: 'Doctor', width: 150 },
      { field: 'date', headerName: 'Date', width: 150 },
      { field: 'time', headerName: 'Time', width: 150 },
      { field: 'reason', headerName: 'Reason for Visit', width: 200 },
    ],
    rows: [
      { id: 1, patientName: 'Harish R', doctor: 'Dr. Suman', date: '2024-09-15', time: '10:00 AM', reason: 'General Checkup' },
      { id: 2, patientName: 'Jayalakshmi', doctor: 'Dr. Suraj', date: '2024-09-16', time: '11:30 AM', reason: 'Follow-up' },
      { id: 3, patientName: 'Ravi Kumar', doctor: 'Dr. Singh', date: '2024-09-17', time: '09:00 AM', reason: 'ENT Consultation' },
      { id: 4, patientName: 'Anjali Verma', doctor: 'Dr. Nair', date: '2024-09-18', time: '02:00 PM', reason: 'Follow-up' },
      { id: 5, patientName: 'Suresh Iyer', doctor: 'Dr. Suman', date: '2024-09-19', time: '10:30 AM', reason: 'Health Screening' },
      { id: 6, patientName: 'Sneha Rao', doctor: 'Dr. Desai', date: '2024-09-20', time: '01:00 PM', reason: 'Eye Examination' },
      { id: 7, patientName: 'Rohit Sharma', doctor: 'Dr. Iyer', date: '2024-09-21', time: '08:45 AM', reason: 'General Consultation' },
      { id: 8, patientName: 'Pooja Menon', doctor: 'Dr. Rao', date: '2024-09-22', time: '12:00 PM', reason: 'Blood Test' },
    ],
  };

  return (
    <div>      
      <DataGridComponent
        heading="Appointments"
        data={appointmentData}
        showCheckbox={false} // No checkbox selection
      />
    </div>
  );
};

export default AppointmentList;
