import React, { useState } from 'react';
import DataGridComponent from './Datagrid'; // Import your custom DataGridComponent
import styles from './BillingForm.module.css'; // Import CSS Module

const BillingForm = ({ handleSubmit }) => {
 // State for managing patient details
 const [patientDetails, setPatientDetails] = useState({
  patientId: '',
  name: '',
  age: '',
  gender: '',
  admissionDate: '',
  dischargeDate: '',
});



  // State for managing billing items
  const [currentItem, setCurrentItem] = useState({
    description: '',
    quantity: 0,
    unitPrice: 0,
    total: 0,
  });
  const [billingItems, setBillingItems] = useState([]);

  // Columns definition for DataGrid
  const columns = [
    { field: 'description', headerName: 'Description', width: 200 },
    { field: 'quantity', headerName: 'Quantity', width: 100 },
    { field: 'unitPrice', headerName: 'Unit Price', width: 100 },
    {
      field: 'total',
      headerName: 'Total',
      width: 100,
      renderCell: (params) => <span>{params.row.quantity * params.row.unitPrice}</span>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 150,
      renderCell: (params) => (
        <button
          className={styles.deleteButton}
          onClick={() => handleDelete(params.row.id)}
        >
          Delete
        </button>
      ),
    },
  ];
  
   // Handle input change for patient details
   const handlePatientInputChange = (e) => {
    const { name, value } = e.target;
    setPatientDetails({ ...patientDetails, [name]: value });
  };
  // Handler for deleting an item
  const handleDelete = (id) => {
    const updatedItems = billingItems.filter((item) => item.id !== id);
    setBillingItems(updatedItems);
  };

  // Handle input change for billing items
  const handleBillingInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: value });
  };

  // Add billing item to the list and update DataGrid
  const handleAddItem = () => {
    if (currentItem.description && currentItem.quantity && currentItem.unitPrice) {
      const total = currentItem.quantity * currentItem.unitPrice;
      const newItem = { ...currentItem, id: billingItems.length + 1, total }; // Add unique id
      setBillingItems([...billingItems, newItem]);
      setCurrentItem({ description: '', quantity: 0, unitPrice: 0, total: 0 }); // Reset input fields
    }
  };

  return (
    <div className={styles.billingForm}>
      {/* Header */}
      <h2 className={styles.header}>Billing Form</h2>
      {/* Top Section for Patient Details */}
      <div className={styles.patientDetails}>
        {/* First Line: Patient ID and Search Button */}
        <div className={styles.patientField}>
          <label>Patient ID:</label>
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="patientId"
              value={patientDetails.patientId}
              onChange={handlePatientInputChange}
            />
            <button className={styles.searchButton}>Search</button>
          </div>
        </div>

        {/* Second Line: Name and Age */}
        <div className={styles.row}>
          <div className={styles.patientField}>
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={patientDetails.name}
              onChange={handlePatientInputChange}              
            />
          </div>
          <div className={styles.patientField}>
            <label>Age:</label>
            <input
              type="text"
              name="age"
              value={patientDetails.age}
              onChange={handlePatientInputChange}              
            />
          </div>
        </div>

        {/* Third Line: Gender and Admission Date */}
        <div className={styles.row}>
          <div className={styles.patientField}>
            <label>Gender:</label>
            <input
              type="text"
              name="gender"
              value={patientDetails.gender}
              onChange={handlePatientInputChange}              
            />
          </div>
          <div className={styles.patientField}>
            <label>Admission Date:</label>
            <input
              type="text"
              name="admissionDate"
              value={patientDetails.admissionDate}
              onChange={handlePatientInputChange}              
            />
          </div>
        </div>

        {/* Fourth Line: Discharge Date */}
        <div className={styles.patientField}>
          <label>Discharge Date:</label>
          <input
            type="date"
            name="dischargeDate"
            value={patientDetails.dischargeDate}
            onChange={handlePatientInputChange}
          />
        </div>
      </div>

      <div className={styles.billingInputs}>
        <h3>Billing Items</h3>
        <div className={styles.billingInputRow}>
          <div className={styles.billingInputField}>
            <label>Description</label>
            <input
              type="text"
              name="description"
              placeholder="Description"
              value={currentItem.description}
              onChange={handleBillingInputChange}
              className={styles.inputField}
            />
          </div>
          <div className={styles.billingInputField}>
            <label>Quantity</label>
            <input
              type="number"
              name="quantity"
              placeholder="Quantity"
              value={currentItem.quantity}
              onChange={handleBillingInputChange}
              className={styles.inputField}
            />
          </div>
          <div className={styles.billingInputField}>
            <label>Unit Price</label>
            <input
              type="number"
              name="unitPrice"
              placeholder="Unit Price"
              value={currentItem.unitPrice}
              onChange={handleBillingInputChange}
              className={styles.inputField}
            />
          </div>
          <div className={styles.billingInputField}>
            <label>Total</label>
            <input
              type="number"
              name="total"
              placeholder="Total"
              value={currentItem.quantity * currentItem.unitPrice} // Calculated field
              readOnly
              className={styles.inputField}
            />
          </div>
        </div>

        {/* Add Item Button */}
        <div className={styles.buttonGroup}>
          <button onClick={handleAddItem} className={styles.addItemButton}>
            Add Item
          </button>
          <button onClick={handleSubmit} className={styles.submitButton}>Submit</button>          
        </div>
      </div>

      {/* DataGrid Component */}
      <div className={styles.billingItemsGrid}>
        <DataGridComponent
          heading="Billing Items"
          data={{ columns, rows: billingItems }} // Pass columns and updated billingItems
          showCheckbox={false} // You can toggle checkbox selection if needed
        />
      </div>
    </div>
  );
};

export default BillingForm;
