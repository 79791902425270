// App.js
import React from 'react';
import RegistrationForm from '../components/RegistrationForm'
import DataGridComponent from '../components/Datagrid'
import Layout from '../components/Layout';
import style from './RegistrationPage.module.css'

function RegistrationPage() {
  const clinicData = {
    columns: [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'name', headerName: 'Name', width: 200 },
      { field: 'phone', headerName: 'Phone Number', width: 150 },
      { field: 'clinic', headerName: 'Clinic Name', width: 200 },
    ],
    rows: [
      { id: 1, name: 'Aman Kapoor', phone: '9876543210', clinic: 'OPD' },
      { id: 2, name: 'Kavya Rao', phone: '8765432109', clinic: 'Ophthalmology' },
      { id: 3, name: 'Sunil Jain', phone: '7654321098', clinic: 'ENT' },
      { id: 4, name: 'Ritu Agarwal', phone: '6543210987', clinic: 'OPD' },
      { id: 5, name: 'Varun Bhatia', phone: '5432109876', clinic: 'Ophthalmology' },      
    ]
  };

  const appointmentData = {
    columns: [
      { field: 'id', headerName: 'ID', width: 90 },
      { field: 'patientName', headerName: 'Patient Name', width: 150 },
      { field: 'date', headerName: 'Date', width: 150 },
      { field: 'time', headerName: 'Time', width: 150 },
      { field: 'doctor', headerName: 'Doctor', width: 150 },
      { field: 'status', headerName: 'Status', width: 150 },
    ],
    rows: [
      { id: 1, patientName: 'Amit Kumar', date: '2024-09-10', time: '10:00 AM', doctor: 'Dr. Singh', status: 'Scheduled' },
      { id: 2, patientName: 'Priya Sharma', date: '2024-09-11', time: '11:00 AM', doctor: 'Dr. Patel', status: 'Scheduled' },
      { id: 3, patientName: 'Rahul Verma', date: '2024-09-12', time: '09:00 AM', doctor: 'Dr. Iyer', status: 'Completed' },
      { id: 4, patientName: 'Anjali Gupta', date: '2024-09-12', time: '01:00 PM', doctor: 'Dr. Rao', status: 'Canceled' },
      { id: 5, patientName: 'Vikas Mehta', date: '2024-09-13', time: '02:00 PM', doctor: 'Dr. Desai', status: 'Scheduled' },      
    ]
  };
  
  

  
  return (
    <Layout>  
      <div className={style.leftSection}>
          <RegistrationForm />
      </div>    
      <div className={style.rightSection}>
        <DataGridComponent heading="Patient Data" data={clinicData} showCheckbox={false} />
        <DataGridComponent heading="Tomorrows's Appointment List" data={appointmentData} showCheckbox={false} />        
      </div>
    </Layout>
  );
}

export default RegistrationPage;
