import Layout from '../components/Layout'
import HelpAndSupport from '../components/HelpandSupport';
const Help = () =>{
    return(
        <Layout>
            <HelpAndSupport/>
        </Layout>
    )
}

export default Help;