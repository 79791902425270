import LayoutPlain from '../components/LayoutPlain';
import PatientFeedbackForm from '../components/PatientFeedbackForm';
const PatientFeedbackPage = () =>{
    return(
        <LayoutPlain>
            <PatientFeedbackForm/>
        </LayoutPlain>
    )
}

export default PatientFeedbackPage;