import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box } from '@mui/material';
import styles from './Datagrid.module.css'; // Import custom CSS file for styling

const DataGridComponent = ({ heading, data, showCheckbox }) => {
  const { columns, rows } = data;

  const [page, setPage] = useState(0);
  const rowsPerPage = 10;

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  return (
    <Box className={styles.datagridContainer}>
      <h2>{heading}</h2>
      <div style={{ height: 400, width: '100%' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={rowsPerPage}
          pagination
          page={page}
          onPageChange={(params) => handlePageChange(params.page)}
          checkboxSelection={showCheckbox} // Toggle checkbox based on the prop
        />
      </div>      
    </Box>
  );
};

export default DataGridComponent;
