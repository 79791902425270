import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LayoutPlain.module.css';
import logo from '../assets/logo.png'; // Replace with the actual logo path

const LayoutPlain = ( {children }) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className={styles.layoutContainer}>
       <nav className={styles.navbar}>
        <div className={styles.logoContainer}>
          <img src={logo} alt="Logo" className={styles.logoImage} /> {/* Logo Image */}
          <div className={styles.brandNameContainer}>
            <span className={styles.brandNameTitle}>Autoops</span> {/* Main Title */}
            <span className={styles.brandNameSubtitle}>Simplifying medical care</span> {/* Subtitle */}
          </div>
        </div>
        <button className={styles.backButton} onClick={handleBackClick}>Back</button>
      </nav>
      <div className={styles.content}>          
          {children}
      </div> {/* Content area will scroll */}
    </div>
  );
};

export default LayoutPlain;
