import React, { createContext, useState } from 'react';

// Create Context
export const ConsultantContext = createContext();


export const ConsultantProvider = ({ children }) => { 
  const [selectedImage, setSelectedImage] = useState(null);

  // State to determine if the upload mode is active
  const [uploadMode, setUploadMode] = useState(true);

  // State for prescription text
  const [prescriptionText, setPrescriptionText] = useState('');

  // Function to handle image selection
  const handleImageSelect = (image) => {
    setSelectedImage(image);
    setUploadMode(false); 
  };

  // Function to handle entering upload mode
  const handleNewPrescription = () => {
    setSelectedImage(null);
    setUploadMode(true);
  };

  // Function to handle saving the prescription
  const handleSave = () => {
    alert('Saved Successfully');
    // Further logic for saving can be added here
  };

  // Function to clear the prescription data
  const handleClear = () => {
    setSelectedImage(null);
    setPrescriptionText('');
    setUploadMode(false);
  };

  return (
    <ConsultantContext.Provider
      value={{
        selectedImage,
        uploadMode,
        prescriptionText,
        setPrescriptionText,
        handleImageSelect,
        handleNewPrescription,
        handleSave,
        handleClear,
      }}
    >
      {children}
    </ConsultantContext.Provider>
  );
};
