// LoginForm.js
import React, { useState, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './LoginForm.module.css'; // Import the CSS Module
import logo from '../assets/logo.png'; // Import the logo from the root folder
import brandname from '../assets/brandname.png'

function LoginForm() {
  const [showMfaPopup, setShowMfaPopup] = useState(false); // State to control MFA popup visibility
  const [mfaCode, setMfaCode] = useState(''); // State for MFA code input
  const navigate = useNavigate();
  const mfaInputRef = useRef(null); // Create a reference for the MFA input field

  const handleSSOLoginClick = () => {
    // Simulate SSO Login Success and show MFA popup
    setShowMfaPopup(true);
    setTimeout(() => mfaInputRef.current?.focus(), 100);
  };

  const handleMfaSubmit = () => {
    // Validate MFA Code (Mock validation)
    if (mfaCode === '123456') { // Replace with real validation logic
      navigate('/dashboard'); // Redirect to dashboard
    } else {
      alert('Invalid MFA Code. Please try again.'); // Show error message
    }
  };

  return (
    <div className={styles.container}>
      {/* Left Half: Logo, Brand Name, Key Features, and Advantages */}
      <div className={styles.leftSection}>
        <div className={styles.navbar}>
          <img src={logo} alt="Logo" className={styles.topLogo} />
          <span className={styles.brandName}></span>
        </div>
        <div className={styles.content}>
          <div className = {styles.logoContainer}>
            <img src={brandname} alt="Login Logo" className={styles.loginLogo} />
          </div>
          <h2 className={styles.heading}>Key Features</h2>
          <ul className={styles.bulletPoints}>
            <li>Patient Registration and Appointment Scheduling</li>
            <li>Electronic Medical Records (EMR) and Health Data Management</li>
            <li>Automated Reminders and Notifications for Appointments</li>
            <li>Reporting and Analytics for Patient and Financial Data</li>
            <li>Secure Communication between Patients and Healthcare Providers</li>
            <li>Role-Based Access Control for Enhanced Security</li>
          </ul>          
        </div>
      </div>

      {/* Right Half: SSO Login */}
      <div className={styles.rightSection}>
        <div className={styles.ssoContainer}>
          <h2 className={styles.ssoHeading}>Welcome to PMS</h2>
          <p className={styles.ssoText}>Sign in with your SSO account</p>
          <button className={styles.ssoButton} onClick={handleSSOLoginClick}>
            Sign In with SSO
          </button>
        </div>
      </div>

      {/* MFA Popup */}
      {showMfaPopup && (
        <div className={styles.mfaPopup}>
          <div className={styles.mfaContent}>
            <h3>Enter TOPT</h3>
            <input
                type="password"
                className={styles.mfaInput}
                value={mfaCode}
                onChange={(e) => setMfaCode(e.target.value)}
                placeholder="Enter code from authenticator"
                ref={mfaInputRef}  // Attach the ref here
            />
            <button className={styles.mfaSubmitButton} onClick={handleMfaSubmit}>
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginForm;
