// App.js
import React from 'react';
import Layout from '../components/Layout'
import { ConsultantProvider } from '../context/ConsultantContext';
import ConsultantForm from '../components/ConsultantForm'
import style from './ConsultantPage.module.css'
import PrescriptionPreview from '../components/PrescriptionPreview'

function ConsultantPage() {
  return (    
    <Layout>
      <ConsultantProvider>
          <div className={style.leftSection}>
              <ConsultantForm />
          </div>    
          <div className={style.rightSection}>            
              <PrescriptionPreview />
          </div>      
      </ConsultantProvider>
    </Layout>
  );
}

export default ConsultantPage;
