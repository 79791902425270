// Dashboard.js
import React from 'react';
import { AppBar, Toolbar, Typography, IconButton, Grid, Card, CardContent } from '@mui/material'; //Snackbar, Alert
import { DataGrid } from '@mui/x-data-grid';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SettingsIcon from '@mui/icons-material/Settings';
import styles from './Dashboard.module.css';

const Dashboard = () => {
  // Sample data for DataGrid and charts
  const chartData = [
    { name: 'Jan', Appointments: 30, Admissions: 12 },
    { name: 'Feb', Appointments: 45, Admissions: 20 },
    { name: 'Mar', Appointments: 50, Admissions: 15 },
  ];

  const rows = [
    { id: 1, patientName: 'Darshan', date: '2024-09-10', time: '10:00 AM', doctor: 'Dr. Suresh', status: 'Scheduled' },
    { id: 2, patientName: 'Santhosh', date: '2024-09-11', time: '11:00 AM', doctor: 'Dr. Junaid', status: 'Scheduled' },
  ];

  const columns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'patientName', headerName: 'Patient Name', width: 150 },
    { field: 'date', headerName: 'Date', width: 150 },
    { field: 'time', headerName: 'Time', width: 150 },
    { field: 'doctor', headerName: 'Doctor', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  const satisfactionData = [
    { month: 'March', satisfaction: 80 },
    { month: 'April', satisfaction: 85 },
    { month: 'May', satisfaction: 78 },
    { month: 'June', satisfaction: 90 },
    { month: 'July', satisfaction: 88 },
    { month: 'August', satisfaction: 92 },
  ];

  // Sample data for Doctors DataGrid
  // Sample data for Doctors DataGrid with additional "Today's Availability" column
  const doctorRows = [
    { id: 1, name: 'Dr. Arjun Verma', specialization: 'Cardiologist', contact: '9876543210', availability: 'Available' },
    { id: 2, name: 'Dr. Kavita Sharma', specialization: 'Pediatrician', contact: '8765432109', availability: 'Not Available' },
    { id: 3, name: 'Dr. Rakesh Singh', specialization: 'Neurologist', contact: '7654321098', availability: 'Available' },
  ];

  const doctorColumns = [
    { field: 'id', headerName: 'ID', width: 90 },
    { field: 'name', headerName: 'Name', width: 200 },
    { field: 'specialization', headerName: 'Specialization', width: 200 },
    { field: 'contact', headerName: 'Contact Details', width: 200 },
    { field: 'availability', headerName: "Today's Availability", width: 200 },
  ];


  return (
    <div className={styles.dashboardContainer}>
      {/* Header Section */}
      <AppBar position="static" className={styles.appBar}>
        <Toolbar>
          <Typography variant="h6" className={styles.title}>
            Patient Management System
          </Typography>
          <IconButton color="inherit">
            <NotificationsIcon />
          </IconButton>
          <IconButton color="inherit">
            <SettingsIcon />
          </IconButton>
          <IconButton color="inherit">
            <AccountCircleIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Content Section */}
      <div className={styles.content}>
        <Grid container spacing={3}>
          {/* Key Metrics Cards */}
          <Grid item xs={12} sm={6} md={3}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">Total Patients</Typography>
                <Typography variant="h3" color="primary">98</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">Appointments</Typography>
                <Typography variant="h3" color="primary">45</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">Admissions</Typography>
                <Typography variant="h3" color="primary">10</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h5">Monthly Billing</Typography>
                <Typography variant="h3" color="primary">₹3,00,000</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Data Visualizations */}
          <Grid item xs={12} md={6}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h6">Appointment Trends</Typography>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={chartData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="Appointments" stroke="#8884d8" />
                    <Line type="monotone" dataKey="Admissions" stroke="#82ca9d" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Upcoming Appointments Table */}
          <Grid item xs={12} md={6}>
            <Card className={styles.card}>
              <CardContent>
                <Typography variant="h6">Upcoming Appointments</Typography>
                <div style={{ height: 300, width: '100%' }}>
                  <DataGrid rows={rows} columns={columns} pageSize={5} checkboxSelection />
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={6}>
          <Card className={styles.card}>
            <CardContent>
              <Typography variant="h6">Patient Satisfaction Index - Last 6 Months</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={satisfactionData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="satisfaction" stroke="#8884d8" />
                </LineChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card className={styles.card}>
            <CardContent>
              <Typography variant="h6">Doctor Information</Typography>
              <ResponsiveContainer width="100%" height={300}>
                <DataGrid rows={doctorRows} columns={doctorColumns} pageSize={5} checkboxSelection />
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

          {/* Notifications and Alerts */}
          {/* <Grid item xs={12}>
            <Snackbar open={true} autoHideDuration={6000}>
              <Alert severity="info">New patient registered today!</Alert>
            </Snackbar>
          </Grid> */}
        </Grid>
      </div>
    </div>
  );
};

export default Dashboard;
