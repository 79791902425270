import React, { useState } from 'react';
import styles from './PatientFeedbackForm.module.css';

const PatientFeedbackForm = () => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState('');
  const [recommend, setRecommend] = useState('');
  const [suggestions, setSuggestions] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (rating && feedback && recommend) {
      // Handle form submission logic (e.g., send feedback to server)
      setSubmitted(true);
    } else {
      alert('Please complete all required fields.');
    }
  };

  return (
    <div className={styles.feedbackFormContainer}>
      {!submitted ? (
        <form onSubmit={handleSubmit}>
          <h1 className={styles.formHeading}>Patient Feedback Form</h1>

          <div className={styles.formGroup}>
            <label htmlFor="service-rating">How satisfied are you with our service?</label>
            <div className={styles.emojiRating}>
              <span onClick={() => setRating(1)} className={`${styles.emoji} ${rating === 1 ? styles.active : ''}`} role="img" aria-label="angry">😡</span>
              <span onClick={() => setRating(2)} className={`${styles.emoji} ${rating === 2 ? styles.active : ''}`} role="img" aria-label="disappointed">😕</span>
              <span onClick={() => setRating(3)} className={`${styles.emoji} ${rating === 3 ? styles.active : ''}`} role="img" aria-label="neutral">😐</span>
              <span onClick={() => setRating(4)} className={`${styles.emoji} ${rating === 4 ? styles.active : ''}`} role="img" aria-label="satisfied">🙂</span>
              <span onClick={() => setRating(5)} className={`${styles.emoji} ${rating === 5 ? styles.active : ''}`} role="img" aria-label="very satisfied">😃</span>
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="feedback">What do you like/not like about our service?</label>
            <textarea
              id="feedback"
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Please fill in your answer"
              required
              className={styles.textArea}
            />
          </div>

          <div className={styles.formGroup}>
            <label>Would you recommend our services to others?</label>
            <div className={styles.radioGroup}>
              <input type="radio" id="recommend-yes" name="recommend" value="yes" onChange={() => setRecommend('yes')} required />
              <label htmlFor="recommend-yes">Yes</label>

              <input type="radio" id="recommend-no" name="recommend" value="no" onChange={() => setRecommend('no')} required />
              <label htmlFor="recommend-no">No</label>
            </div>
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="suggestions">Any suggestions for improvement?</label>
            <textarea
              id="suggestions"
              value={suggestions}
              onChange={(e) => setSuggestions(e.target.value)}
              placeholder="Please fill in your answer"
              className={styles.textArea}
            />
          </div>

          <button type="submit" className={styles.submitButton}>Submit</button>
        </form>
      ) : (
        <div className={styles.thankYouMessage}>
          <h2>Thank you for your feedback!</h2>
        </div>
      )}
    </div>
  );
};

export default PatientFeedbackForm;
