// PatientSearch.js
import React, { useState } from 'react';
import styles from './PatientSearch.module.css';
import mr1 from '../assets/mr1.png';
import mr2 from '../assets/mr2.png';
import mr3 from '../assets/mr3.png';

const PatientSearch = () => {
    const [patientId, setPatientId] = useState('');
    const [patientName, setPatientName] = useState('')
    const [selectedImage, setSelectedImage] = useState(null);

    const handleSearch = () => {
        switch (patientId.toLowerCase()) { // Ensure case-insensitivity
            case '123456':
                setPatientName('Suraj Pandey')
                setSelectedImage(mr1);
                break;
            case '987654':
                setPatientName('Kavya Kumar')
                setSelectedImage(mr2);
                break;
            case '123987':
                setPatientName('Nithin Raj')
                setSelectedImage(mr3);
                break;
            default:
                setSelectedImage(null); // No record found
                break;
        }
    };

    return (
        <div className={styles.container}>
            <h2>Search Patient Medical Records</h2>
            <div className={styles.searchSection}>
                <input
                    type="text"
                    placeholder="Enter Patient ID/Patient Name/Phone number"
                    value={patientId}
                    onChange={(e) => setPatientId(e.target.value)}
                    className={styles.searchInput}
                />
                <button onClick={handleSearch} className={styles.searchButton}>
                    Search
                </button>
            </div>
            {selectedImage ? (
                <div className={styles.resultSection}>
                    <p>Patient Name: {patientName}</p>
                    <img src={selectedImage} alt="Medical Record" className={styles.medicalRecordImage} />
                </div>
            ) : (
                patientId && <p className={styles.errorMessage}>No records found for this Patient ID</p>
            )}
        </div>
    )
}

export default PatientSearch;
