import React, { useState } from 'react';
import styles from './UserProfileForm.module.css';

const UserProfileForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    gender: '',
    nationality: '',
    email: '',
    phone: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    postalCode: '',
    country: '',
    jobTitle: '',
    department: '',
    employeeId: '',
    joiningDate: '',
    rolesResponsibilities: '',
    username: '',
    password: '',
    confirmPassword: '',
    userRole: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log('Form Data:', formData);
  };

  return (
    <form className={styles.userProfileForm} onSubmit={handleSubmit}>
      <h2 className={styles.heading}>User Profile</h2>

      {/* Personal Information Section */}
      <div className={styles.section}>
        <h3 className={styles.sectionHeading}>Personal Information</h3>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>First Name</label>
            <input type="text" className={styles.input} placeholder="First Name" name="firstName" value={formData.firstName} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Last Name</label>
            <input type="text" className={styles.input} placeholder="Last Name" name="lastName" value={formData.lastName} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Date of Birth</label>
            <input type="date" className={styles.input} name="dateOfBirth" value={formData.dateOfBirth} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Gender</label>
            <select className={styles.input} name="gender" value={formData.gender} onChange={handleChange}>
              <option value="">Select Gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Nationality</label>
            <input type="text" className={styles.input} placeholder="Nationality" name="nationality" value={formData.nationality} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Profile Picture</label>
            <input type="file" className={styles.input} name="profilePicture" />
          </div>
        </div>
      </div>

      {/* Contact Information Section */}
      <div className={styles.section}>
        <h3 className={styles.sectionHeading}>Contact Information</h3>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Email Address</label>
            <input type="email" className={styles.input} placeholder="Email Address" name="email" value={formData.email} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Phone Number</label>
            <input type="text" className={styles.input} placeholder="Phone Number" name="phone" value={formData.phone} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Address Line 1</label>
            <input type="text" className={styles.input} placeholder="Address Line 1" name="address1" value={formData.address1} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Address Line 2</label>
            <input type="text" className={styles.input} placeholder="Address Line 2" name="address2" value={formData.address2} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>City</label>
            <input type="text" className={styles.input} placeholder="City" name="city" value={formData.city} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>State/Province</label>
            <input type="text" className={styles.input} placeholder="State" name="state" value={formData.state} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Postal Code</label>
            <input type="text" className={styles.input} placeholder="Postal Code" name="postalCode" value={formData.postalCode} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Country</label>
            <select className={styles.input} name="country" value={formData.country} onChange={handleChange}>
              <option value="">Select Country</option>
              <option value="India">India</option>
              {/* Add more countries as needed */}
            </select>
          </div>
        </div>
      </div>

      {/* Professional Details Section */}
      <div className={styles.section}>
        <h3 className={styles.sectionHeading}>Professional Details</h3>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Employee ID</label>
            <input type="text" className={styles.input} placeholder="Employee ID" name="employeeId" value={formData.employeeId} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Job Title</label>
            <input type="text" className={styles.input} placeholder="Job Title" name="jobTitle" value={formData.jobTitle} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Department</label>
            <input type="text" className={styles.input} placeholder="Department" name="department" value={formData.department} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Joining Date</label>
            <input type="date" className={styles.input} name="joiningDate" value={formData.joiningDate} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.columnFull}>
            <label>Roles and Responsibilities</label>
            <textarea className={styles.textarea} placeholder="Roles and Responsibilities" name="rolesResponsibilities" value={formData.rolesResponsibilities} onChange={handleChange} />
          </div>
        </div>
      </div>

      {/* Account Settings Section */}
      <div className={styles.section}>
        <h3 className={styles.sectionHeading}>Account Settings</h3>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Username</label>
            <input type="text" className={styles.input} placeholder="Username" name="username" value={formData.username} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>Password</label>
            <input type="password" className={styles.input} placeholder="Password" name="password" value={formData.password} onChange={handleChange} />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.column}>
            <label>Confirm Password</label>
            <input type="password" className={styles.input} placeholder="Confirm Password" name="confirmPassword" value={formData.confirmPassword} onChange={handleChange} />
          </div>
          <div className={styles.column}>
            <label>User Role</label>
            <select className={styles.input} name="userRole" value={formData.userRole} onChange={handleChange}>
              <option value="">Select User Role</option>
              <option value="Admin">Admin</option>
              <option value="Doctor">Doctor</option>
              <option value="Nurse">Nurse</option>
              <option value="Receptionist">Receptionist</option>
            </select>
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className={styles.buttonContainer}>
        <button type="submit" className={styles.saveButton}>Save</button>
        <button type="button" className={styles.cancelButton} onClick={() => setFormData({})}>Cancel</button>
      </div>
    </form>
  );
};

export default UserProfileForm;
